import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Snackbar, TextField, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate } from 'react-router-dom';
import DynamicForm from './DynamicForm';

const DocHelperModal = ({ open, onClose, onSubmit, userEmail, docId, user, updateDocActions }) => {
  const [signed, setSigned] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('config');
  const [isExploding, setIsExploding] = React.useState(false);
  const [explosionCoords, setExplosionCoords] = useState(null);
  const [apiData, setApiData] = useState(null);
  // const [populateFields, setPopulateFields] = useState(false);
  // const [isAlreadyPopulated, setIsAlreadyPopulated] = useState(false);
  const [readyForSign, setReadyForSign] = useState(false);
  const [userEmailField, setUserEmailField] = useState(null);
  const [userNameField, setUserNameField] = useState(null);
  const [hasSetFields, setHasSetFields] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [invitePairs, setInvitePairs] = useState([]);
  const [emptyFields, setEmptyFields] = useState([]);
  const [updateAttributes, setUpdateAttributes] = useState({});
  const [editableDisplayName, setEditableDisplayName] = useState(user.displayName);
  const [isSigning, setIsSigning] = useState(false);

  const navigate = useNavigate();

  const handleButtonCoords = (event) => {
    if (!explosionCoords) {
      const buttonRect = event.currentTarget.getBoundingClientRect();
      const buttonCenterX = buttonRect.left + buttonRect.width / 2;
      const buttonCenterY = buttonRect.top + buttonRect.height / 2;
      setExplosionCoords({ x: buttonCenterX, y: buttonCenterY });
    }
  };

  const handleSignedStatus = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/sign/${docId}?signer_email=${encodeURIComponent(userEmail)}`);
      setSigned(response.data);
    } catch (error) {
      console.error("Error getting signature:", error);
      alert("Error getting signature. Please try again.");
    }
  }, [docId, userEmail]);

  useEffect(() => {
    if (open) {
      handleSignedStatus();
    }
  }, [open, handleSignedStatus]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/attributes/${docId}`);
        setApiData(response.data);

        // Check if fields are populated
        // if (response.data[userNameField] && response.data[userEmailField]) {
        //   setPopulateFields(true);
        //   setIsAlreadyPopulated(true);
        // } else {
        //   setIsAlreadyPopulated(false);
        // }

        // Check if the fields have been set before
        if (!hasSetFields) {
          // Filter for fields that do not contain "CLIENT"
          const filteredFields = Object.keys(response.data).filter(key => !key.includes("CLIENT"));

          // Find the first NAME and EMAIL fields that are not "CLIENT"
          const nameField = filteredFields.find(key => key.includes("- NAME"));
          const emailField = filteredFields.find(key => key.includes("- EMAIL"));

          setUserEmailField(emailField);
          setUserNameField(nameField);

          setHasSetFields(true); // Mark that fields have been set
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [setApiData, docId, hasSetFields, userNameField, userEmailField]); // Add hasSetFields to dependencies


  const handleSignValidation = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/attributes/${docId}`);
      const data = response.data;

      // Check if any NAME and EMAIL fields in apiData match the user's credentials
      const emailFieldDefined = Object.keys(apiData).some(key => key.includes("EMAIL") && apiData[key] === userEmail);

      const restFieldsReady = Object.keys(data)
        .filter(key => !key.includes("SIGNATURE"))
        .every(key => data[key]);

      // Set allFieldsReady to true if email field is defined
      const allFieldsReady = emailFieldDefined && restFieldsReady;

      setReadyForSign(allFieldsReady && !signed); // Set readyForSign to true if all conditions are met

      setSelectedTab('sign');
    } catch (error) {
      console.error("Error validating sign status:", error);
    }
  };




  const handleDocumentChange = async (changedAttributes) => {
    const updatedApiData = {
      ...apiData,
      ...changedAttributes
    };

    // Filter out empty non-SIGNATURE fields
    const emptyFields = Object.keys(updatedApiData)
      .filter(key => !key.includes('- SIGNATURE')) // Exclude SIGNATURE fields
      .filter(key => !updatedApiData[key] || updatedApiData[key].trim() === ''); // Check for empty fields

    if (!(emptyFields.length === 0)) {
      setEmptyFields(emptyFields);
      return;
    }

    const detectedPairs = [];

    // 1. Check for NAME/EMAIL pairs completeness
    for (const key in changedAttributes) {
      // console.log('key: ', key);
      if (key.includes('- NAME')) {
        const emailKey = key.replace('- NAME', '- EMAIL');

        // If both NAME and EMAIL are filled, add them to detectedPairs
        if (changedAttributes[key] && changedAttributes[emailKey] && (key in apiData) && (emailKey in apiData) && (key !== userNameField) && (emailKey !== userEmailField)) {
          detectedPairs.push({
            name: changedAttributes[key],
            email: changedAttributes[emailKey]
          });
        }
      }
    }
    console.log(detectedPairs);

    // 2. If detectedPairs are found, check other required fields
    if (detectedPairs.length > 0) {

      // Set invite pairs and open modal if all conditions are met
      setInvitePairs(detectedPairs);
      setInviteModalOpen(true);
      setUpdateAttributes(changedAttributes);
    }
  };

  const updateDocument = async (attributes) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/documents/${docId}`, {
        signatures: { signatures: attributes },
        attributes: {}
      });
      localStorage.setItem('selectedAssetId', response.data);
      navigate(`/home/document/${response.data}`);
      updateDocActions();
    } catch (error) {
      console.error("Error updating document:", error);
      alert("Error updating document. Please try again.");
    }
  };

  const handleInviteConfirm = async () => {
    try {
      await updateDocument(updateAttributes);
      // Trigger invite API call for each pair
      await Promise.all(invitePairs.map(pair =>
        axios.post(`${process.env.REACT_APP_API_URL}/documents/invite/`, { email: pair.email, doc_id: docId }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
      ));
      setInviteModalOpen(false);
      setInvitePairs([]);

    } catch (error) {
      console.error("Error inviting users:", error);
      alert("Error inviting users. Please try again.");
      setInviteModalOpen(false);
    }
  };

  // const handleCheckboxChange = (event) => {
  //   console.log(populateFields, isAlreadyPopulated, readyForSign);
  //   const checked = event.target.checked;
  //   setPopulateFields(checked);

  //   // Update only the first available NAME and EMAIL fields that are not "CLIENT"
  //   setApiData(apiData => ({
  //     ...apiData,
  //     [userNameField]: checked ? user.displayName : "",
  //     [userEmailField]: checked ? userEmail : ""
  //   }));
  // };

  const handleSubmit = async () => {
    function findKeyByValue(obj, value) {
      for (const [key, val] of Object.entries(obj)) {
        if (val === value) {
          return key; // Return the key if the value matches
        }
      }
      return null; // Return null if no matching key is found
    }
    try {
      setIsSigning(true);
      let userEmailField2 = findKeyByValue(apiData, userEmail);

      const signResponse = await axios.post(`${process.env.REACT_APP_API_URL}/documents/sign/${docId}?signer_email=${userEmail}`);
      const signatureKey = `${userEmailField2.replace(" - EMAIL", " - SIGNATURE")}`;

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/documents/${docId}`, {
        signatures: {
          signatures: { [signatureKey]: editableDisplayName }
        },
        attributes: {}
      });
      if (signResponse.status === 200) {
        setSnackbarOpen(true);
        setReadyForSign(false);
        setSigned(true);
      }
      localStorage.setItem('selectedAssetId', response.data);
      setIsSigning(false);
      setIsExploding(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      navigate(`/home/document/${response.data}`);
    } catch (error) {
      console.error("Error signing document:", error);
      alert("Error signing document. Please try again.");
    } finally {
      setIsExploding(false);
    }
  };

  if (!open) return null;

  return (
    <>
      <Box class="config-box">
        <ButtonGroup class="config-box--btns">
          <Button class="btn btn-orange" onClick={() => setSelectedTab('config')} fullWidth>
            Config
          </Button>
          <Button class="btn btn-orange" onClick={() => handleSignValidation()} fullWidth>
            Sign
          </Button>
        </ButtonGroup>
        {selectedTab === 'config' && (
          <>
            <Typography class="config-box--title" variant="h2">
              Config ⚙️
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <Box class="config-box--content">
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={populateFields}
                    onChange={handleCheckboxChange}
                    disabled={isAlreadyPopulated}
                  />
                }
                label="I am signing this document"
              /> */}
              {apiData ? (
                <DynamicForm apiData={apiData} onSubmitClick={handleDocumentChange} emptyFields={emptyFields} />
              ) : (
                <Typography class="config-box--content">Loading...</Typography>
              )}
            </Box>
          </>
        )}

        {selectedTab === 'sign' && (
          <>
            <Typography class="config-box--title" variant="h2">
              Sign 🖋️
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <Box class="config-box--content">
              {isExploding && (
                <ConfettiExplosion zIndex={9999} force={0.4} width={800} style={{
                  position: 'fixed',
                  left: explosionCoords.x,
                  zIndex: 9999,
                  pointerEvents: 'none'
                }}
                />
              )}

              {/* {readyForSign && (
                  <Typography
                    variant="body1"
                    sx={{ alignSelf: 'center', margin: 2 }}
                  >
                    Signing as {user.displayName} with email {userEmail}
                  </Typography>
                )} */}
              {readyForSign && (
                <div class="config-box--form">
                  <Box class="form-field config-box--field">
                    <TextField
                      label="Signing As"
                      value={editableDisplayName}
                      onChange={(e) => setEditableDisplayName(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <Typography variant="body1">
                      Signing as {editableDisplayName} with email {userEmail}
                    </Typography>
                  </Box>
                </div>

              )}

              {isSigning ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <CircularProgress sx={{ color: '#ff914d' }} />
                </Box>
              ) : (
                <Button
                  onClick={(e) => {
                    handleButtonCoords(e);
                    handleSubmit();
                  }}
                  className="btn btn-orange"
                  disabled={!readyForSign}
                >
                  Adopt and Sign
                </Button>
              )}
            </Box>
          </>
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}  // Set how long the Snackbar will be visible (6000ms = 6 seconds)
          onClose={() => setSnackbarOpen(false)}  // Close the Snackbar when the duration expires
          // anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          fullWidth
        >
          {/* <MuiAlert severity="success" sx={{ width: '100%' }}> */}
          <MuiAlert severity="success">
            Document signed successfully!
          </MuiAlert>
        </Snackbar>
      </Box>
      {/* </Box> */}

      <Dialog open={inviteModalOpen} onClose={() => setInviteModalOpen(false)}>
        <DialogTitle>Invite Users to Sign</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to invite the following users to sign?</Typography>
          <ul>
            {invitePairs.map((pair, index) => (
              <li key={index}>
                {pair.name}, {pair.email}
              </li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInviteConfirm} color="primary">
            Yes
          </Button>
          <Button onClick={() => setInviteModalOpen(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocHelperModal;



