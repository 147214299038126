import axios from 'axios';

export const updateDocActions = async (docId, docText) => {
  try {
    const actionFlowPromise = axios.post(
      `${process.env.REACT_APP_API_URL}/actionflow`, 
      { document: docText.trim() }
    );

    const actionResult = await actionFlowPromise;

    await axios.put(`${process.env.REACT_APP_API_URL}/documents/${docId}`, {
      signatures: {},
      attributes: { actions: actionResult.data.response },
    });

    console.log("updated document actions");
  } catch (error) {
    console.error('Error updating document actions:', error);
    throw error; // Re-throw to allow handling by caller
  }
}; 