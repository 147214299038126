import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Card, CardContent, Chip, Divider, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Popover, Select, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import React, { useState } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const DashboardContainer = styled(Box)({
  padding: '24px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f5f5f7',
  borderRadius: '16px',
  margin: '16px',
});

const DashboardHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '32px',
});

const ScrollableListContainer = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  marginTop: '16px',
  '& .vertical-timeline::before': {
    background: '#ff914d',
    width: '2px',
  },
  '& .vertical-timeline-element-icon': {
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .vertical-timeline-element': {
    margin: '2em 0',
  }
});

const DateSelector = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  background: '#fff',
  padding: '8px 16px',
  borderRadius: '12px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  cursor: 'pointer',
  '&:hover': {
    background: '#f8f8f8',
  },
});

const CustomDateTypography = styled(Typography)({
  fontSize: '18px',
  fontWeight: 600,
  color: '#333',
  margin: '0 16px',
});

const TaskCard = styled(Card)(({ status }) => ({
  width: '100%',
  borderRadius: '12px',
  boxShadow: '0 3px 6px rgba(0,0,0,0.08)',
  background: '#fff',
  transition: 'all 0.2s ease-in-out',
  border: status === 'urgent' ? '1px solid #ff4d4d' : '1px solid rgba(0,0,0,0.05)',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  },
}));

const TaskGroupTitle = styled(Typography)({
  textTransform: 'uppercase',
  letterSpacing: '1px',
  fontWeight: 600,
  color: '#333',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const TaskMetadata = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: '#666',
  gap: '6px',
  fontSize: '0.875rem',
  padding: '4px 8px',
  borderRadius: '6px',
  background: '#f5f5f7',
});

const TaskCount = styled(Box)({
  background: '#ff914d20',
  color: '#ff914d',
  padding: '2px 8px',
  borderRadius: '12px',
  fontSize: '0.75rem',
  fontWeight: 600,
});

const FilterContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  marginLeft: '16px',
});

const FilterSelect = styled(FormControl)({
  minWidth: 200,
  background: '#fff',
  borderRadius: '12px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
  },
});

const FilterSummaryTooltip = styled('div')({
  position: 'absolute',
  top: '100%',
  left: '0',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  padding: '8px',
  marginTop: '8px',
  zIndex: 1000,
  display: 'none',
  '.filter-summary:hover &': {
    display: 'block',
  },
  pointerEvents: 'none',
});

const ToDoListScreen = ({ tasks = [] }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedParties, setSelectedParties] = useState(['all']);
  // const [showTooltip, setShowTooltip] = useState(false);

  const handleDateChange = (days) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + days);
    setSelectedDate(newDate);
  };

  const handleCalendarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  const handleDateSelect = (newDate) => {
    setSelectedDate(newDate);
    handleCalendarClose();
  };

  const open = Boolean(anchorEl);

  const recognizedStatuses = ['ongoing', 'urgent'];
  tasks = tasks.filter(task => task !== undefined);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'urgent':
        return '#ff4d4d';
      default:
        return '#ff914d';
    }
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    
    if (value.includes('all') && !selectedParties.includes('all')) {
      setSelectedParties(['all']);
    } else if (selectedParties.includes('all') && value.length > 1) {
      setSelectedParties(value.filter(party => party !== 'all'));
    } else {
      setSelectedParties(value.length === 0 ? ['all'] : value);
    }
  };

  const handleDeleteParty = (partyToDelete) => {
    const newSelected = selectedParties.filter(party => party !== partyToDelete);
    setSelectedParties(newSelected.length ? newSelected : ['all']);
  };

  const responsibleParties = ['all', ...new Set(tasks
    .filter(task => task?.responsible_party)
    .map(task => task.responsible_party))];

  const filteredTasks = tasks.filter(task => 
    selectedParties.includes('all') || selectedParties.includes(task?.responsible_party)
  );

  const taskGroupsFiltered = filteredTasks.reduce((groups, task) => {
    const group = recognizedStatuses.includes(task.status?.toLowerCase()) 
      ? task.status.toLowerCase() 
      : 'ongoing';
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(task);
    return groups;
  }, {});

  const totalTasksFiltered = filteredTasks.length;

  const handleClearFilters = () => {
    setSelectedParties(['all']);
  };

  return (
    <DashboardContainer>
      <DashboardHeader>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: 600, color: '#333', mb: 1 }}>
            Task Dashboard
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {totalTasksFiltered} {totalTasksFiltered === 1 ? 'task' : 'tasks'} to track
            </Typography>
            <FilterContainer>
              <FilterSelect size="small">
                <InputLabel id="responsible-party-label">Filter by Party</InputLabel>
                <Select
                  labelId="responsible-party-label"
                  multiple
                  value={selectedParties}
                  onChange={handleFilterChange}
                  input={<OutlinedInput label="Filter by Party" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.includes('all') ? (
                        <Chip 
                          size="small"
                          label="All Parties"
                          sx={{ backgroundColor: '#ff914d20', color: '#ff914d' }}
                        />
                      ) : (
                        selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            size="small"
                            sx={{ backgroundColor: '#ff914d20', color: '#ff914d' }}
                          />
                        ))
                      )}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5,
                      },
                    },
                  }}
                >
                  <MenuItem 
                    value="all"
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: '#ff914d20',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <FilterListIcon fontSize="small" />
                      All Parties
                    </Box>
                  </MenuItem>
                  {responsibleParties
                    .filter(party => party !== 'all')
                    .map((party) => (
                      <MenuItem 
                        key={party} 
                        value={party}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: '#ff914d20',
                          },
                        }}
                      >
                        {party}
                      </MenuItem>
                    ))}
                </Select>
              </FilterSelect>
              {!selectedParties.includes('all') && (
                <>
                  {selectedParties.length > 3 ? (
                    <Box 
                      className="filter-summary"
                      sx={{ 
                        position: 'relative',
                        display: 'flex',
                        gap: 1,
                      }}
                    >
                      <Chip
                        label={`${selectedParties.length} filters selected`}
                        sx={{
                          backgroundColor: '#ff914d20',
                          color: '#ff914d',
                        }}
                      />
                      <FilterSummaryTooltip>
                        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                          Selected Filters:
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                          {selectedParties.map(party => (
                            <Box 
                              key={party}
                              sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '4px 8px',
                                borderRadius: '4px',
                              }}
                            >
                              <Typography variant="body2">{party}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </FilterSummaryTooltip>
                    </Box>
                  ) : (
                    selectedParties.map(party => (
                      <Chip
                        key={party}
                        label={party}
                        onDelete={() => handleDeleteParty(party)}
                        sx={{
                          backgroundColor: '#ff914d20',
                          color: '#ff914d',
                          '& .MuiChip-deleteIcon': {
                            color: '#ff914d',
                          },
                        }}
                      />
                    ))
                  )}
                  <Chip
                    icon={<ClearAllIcon />}
                    label="Clear Filters"
                    onClick={handleClearFilters}
                    sx={{
                      backgroundColor: '#f5f5f7',
                      color: '#666',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1rem',
                      },
                    }}
                  />
                </>
              )}
            </FilterContainer>
          </Box>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateSelector onClick={handleCalendarClick}>
            <IconButton onClick={(e) => { e.stopPropagation(); handleDateChange(-1); }} size="small">
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CustomDateTypography>
                {format(selectedDate, 'dd MMM yyyy')}
              </CustomDateTypography>
              <CalendarTodayIcon fontSize="small" sx={{ color: '#666' }} />
            </Box>
            <IconButton onClick={(e) => { e.stopPropagation(); handleDateChange(1); }} size="small">
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </DateSelector>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleCalendarClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '12px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                mt: 1,
              },
            }}
          >
            <StaticDatePicker
              value={selectedDate}
              onChange={handleDateSelect}
              sx={{
                '.MuiPickersDay-root.Mui-selected': {
                  backgroundColor: '#ff914d',
                  '&:hover': {
                    backgroundColor: '#ff914d',
                  },
                },
              }}
            />
          </Popover>
        </LocalizationProvider>
      </DashboardHeader>

      <Divider sx={{ mb: 4 }} />
      
      <ScrollableListContainer>
        {Object.keys(taskGroupsFiltered).length === 0 ? (
          <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
            <AssignmentIcon sx={{ fontSize: 48, color: '#ccc', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No actions yet.
            </Typography>
          </Box>
        ) : (
          <VerticalTimeline layout="1-column-left" lineColor="#ff914d">
            {Object.entries(taskGroupsFiltered).map(([group, groupTasks]) => (
              <VerticalTimelineElement
                key={group}
                iconStyle={{ 
                  background: getStatusColor(group),
                  color: '#fff',
                }}
                contentStyle={{
                  background: 'transparent',
                  boxShadow: 'none',
                  padding: '0 24px',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
                  <TaskGroupTitle>
                    {group}
                  </TaskGroupTitle>
                  <TaskCount>
                    {groupTasks.length} {groupTasks.length === 1 ? 'task' : 'tasks'}
                  </TaskCount>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {groupTasks.map((task, idx) => (
                    <TaskCard key={idx} status={task.status}>
                      <CardContent>
                        <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                          {task.action}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
                          {task.responsible_party}
                        </Typography>
                        <Box display="flex" justifyContent="space-between">
                          <TaskMetadata>
                            <AccessTimeIcon sx={{ fontSize: 18 }} />
                            {task.duration || 'N/A'}
                          </TaskMetadata>
                          <TaskMetadata>
                            <CalendarTodayIcon sx={{ fontSize: 18 }} />
                            {task.trigger || task.deadline || 'N/A'}
                          </TaskMetadata>
                        </Box>
                      </CardContent>
                    </TaskCard>
                  ))}
                </Box>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        )}
      </ScrollableListContainer>
    </DashboardContainer>
  );
};

export default ToDoListScreen;
