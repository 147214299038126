import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { useLocation, useParams } from 'react-router-dom';
import { updateDocActions } from '../utils/documentActions';
import DocHelperModal from './DocHelperModal';

const StyledMainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  width: '100%',
});

const StyledDocumentContainer = styled(Box)({
  flex: 3,
  overflowY: 'auto',
  height: '100%',
});

const StyledHelperContainer = styled(Box)({
  flex: 1,
  borderLeft: '1px solid #ddd',
  overflowY: 'auto',
  height: '100%',
});

const DocxViewer = ({ userEmail, docText, docId, handleLogout, user, tempAccess }) => {
  const [loading, setLoading] = useState(true);
  const [docContent, setDocContent] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { documentName } = useParams();
  const location = useLocation();
  const [selectedDocId, setSelectedDocId] = useState(docId);

  const handleUpdateDocActions = useCallback(async () => {
    try {
      await updateDocActions(docId, docText);
    } catch (error) {
      console.error('Error handling back to home:', error);
    }
  }, [docId, docText]);

  useEffect(() => {
    const fetchDocument = async () => {
      setLoading(true);
      try {

        const queryParams = new URLSearchParams(location.search);
        const signToken = queryParams.get('signToken') || '';

        const fileName = localStorage.getItem("selectedAssetId") || encodeURIComponent(documentName);

        let token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-download-url`, { file_name: fileName, temp_status: tempAccess }, {
          headers: {
            'Authorization': `Bearer ${signToken || token}`,
          }
        });

        const latestVersionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/documents/version/latest/${docId? docId : response.data.docId}`);
        const latest_version = latestVersionResponse.data;

        if (fileName !== latest_version && signToken) {
          
          const newUrl = tempAccess ? `/home/document/${latest_version}?signToken=${signToken}&temp=true` : `/home/document/${latest_version}?signToken=${signToken}`;
          
          window.location.href = newUrl;
          return;
        }

        if (response.status === 200) {
          localStorage.setItem('selectedAssetId', fileName);
          console.log('response.data', response.data);
          setSelectedDocId(response.data.docId);
        }

        const documentUrl = response.data.url;

        setDocContent([{ uri: documentUrl, fileType: 'docx' }]);

      } catch (error) {
        console.error('Error fetching document:', error);
        if (error.status === 401) {
          handleLogout(true);
        }
        setError('Failed to load document');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [docId, documentName, location, handleLogout, handleUpdateDocActions, tempAccess]);

  const handleSignatureSubmit = (signatureData, emails) => {

  };

  return (
    <StyledMainContainer>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <CircularProgress sx={{ color: '#ff914d' }} />
        </Box>
      ) : error ? (
        <StyledDocumentContainer>
          <p>{error}</p>
        </StyledDocumentContainer>
      ) : docContent ? (
        <>
          {/* Left Side: Document Viewer */}
          <StyledDocumentContainer>
            <DocViewer
              documents={docContent}
              pluginRenderers={DocViewerRenderers}
              style={{ width: '100%', height: '100%' }}
              theme={{
                primary: '#ff914d',
                secondary: '#ff914d',
                tertiary: '#ff914d',
                disableThemeScrollbar: true,
              }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false,
                }
              }}
            />
          </StyledDocumentContainer>

          {/* Right Side: DocHelperModal */}
          <StyledHelperContainer>
            {!loading && (
              <DocHelperModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleSignatureSubmit}
                userEmail={userEmail}
                docId={selectedDocId}
                user={user}
                updateDocActions={handleUpdateDocActions}
              />
            )}
          </StyledHelperContainer>
        </>
      ) : (
        <StyledDocumentContainer>
          <p>No document available.</p>
        </StyledDocumentContainer>
      )}
    </StyledMainContainer>
  );
};

export default DocxViewer;
